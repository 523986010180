import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Importa useNavigate para la redirección
import CompMostrarUnProducto from '../Administrador/compBusqueda/busqueda';
import { parseJSON } from 'date-fns';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
const Login = () => {
    const [correo, setCorreo] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loginExitoso, setLoginExitoso] = useState(false);
    const navigate = useNavigate();

    const handleCorreoChange = (event) => {
        setCorreo(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        const datos = {
            correo: correo,
            password: password
        };

        fetch('https://www.teknenl.lol/user/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datos),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('La respuesta de la red no fue válida');
            }
            return response.json();
        })
        .then(data => {
            if (data.token) {
                localStorage.setItem('token', data.token);
                const parsearToken = parseJwt(data.token)
                localStorage.setItem('id',parsearToken.user)
                localStorage.setItem('dir', parsearToken.direccion)
                const {role} = parseJwt(data.token
                )
                setLoginExitoso(true);
                if (role === 2147483647) {
                    navigate('/mostrarproductos')
                }
                else{
                    navigate('/')
                }
                window.location.reload();
            } else {
                setLoginExitoso(false);
            }
        })
        .catch(error => {
            console.error('Error en la solicitud:', error);
            setError('Usuario o contraseña incorrectos. Inténtalo de nuevo.');
        });
    };

    const styleCard = {
        backgroundColor: 'rgb(245, 245, 245)',
        padding: 8,
        borderRadius: '16px',
        boxShadow: '0 12px 12px rgba(0.5, 0.5, 0.5, 0.5)',
    };

    const styleContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
    };

    return (
        <>
            {loginExitoso ? (
                <CompMostrarUnProducto />
            ) : (
                <div className="container">
                    <div className="row justify-content-center" style={styleContainer}>
                        <div className="col-md-5">
                            <div className="card mt-5" style={styleCard}>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-4">Iniciar sesión</h3>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form>
                                        <div className="mb-3">
                                            <input
                                                placeholder='Correo'
                                                type="text"
                                                className="form-control"
                                                value={correo}
                                                onChange={handleCorreoChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                placeholder='Contraseña'
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        <button onClick={handleLogin} className="btn btn-primary col-md-5">Login</button>
                                        <Link to="/sing" className='btn me-2'><span>Registrar</span></Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;