import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const URI = 'https://www.teknenl.lol/producto/';

// Configurar Axios para incluir credenciales
axios.defaults.withCredentials = true;

const CompEditProducto = () => {
const [nombre_Producto, setNombre] = useState('');
const [departamento, setDepartamento] = useState('');
const [marca, setMarca] = useState('');
const [especificaciones, setEspecificaciones] = useState('');
const [descripcion, setDescripcion] = useState('');
const [unidad_Medida, setUnidad_Medida] = useState('');
const [modelo, setModelo] = useState('');
const [img_uno, setImgUno] = useState('');
const [img_dos, setImgDos] = useState('');
const [img_tres, setImgTres] = useState('');
const [precio_Unitario, setPrecioUnitario] = useState('');
const [stock, setStock] = useState('');
const [iva, setIva] = useState('');
const [estado, setEstado] = useState('');
const navigate = useNavigate();
const { id } = useParams();

useEffect(() => {
    getProductoById();
}, [id]);

const getProductoById = async () => {
    try {
    const res = await axios.get(`${URI}/id/${id}`);
    if (res.data) {
        setNombre(res.data.Nombre_Producto || '');
        setDepartamento(res.data.Departamento || '');
        setMarca(res.data.Marca || '');
        setEspecificaciones(res.data.Especificaciones || '');
        setDescripcion(res.data.Descripcion || '');
        setUnidad_Medida(res.data.Unidad_Medida || '');
        setModelo(res.data.Modelo || '');
        setImgUno(res.data.Img_uno || '');
        setImgDos(res.data.Img_dos || '');
        setImgTres(res.data.Img_tres || '');
        setPrecioUnitario(res.data.Precio_Unitario || '');
        setStock(res.data.Stock || '');
        setEstado(res.data.Estado || '');
        setIva(res.data.Iva || '');
    }
    } catch (error) {
    console.error(error);
    }
};

const actualizar = async (e) => {
    e.preventDefault();
    await axios.put(`${URI}${id}`, {
    Nombre_Producto: nombre_Producto,
    Departamento: departamento,
    Marca: marca,
    Especificaciones: especificaciones,
    Descripcion: descripcion,
    Unidad_Medida: unidad_Medida,
    Modelo: modelo,
    Img_uno: img_uno,
    Img_dos: img_dos,
    Img_tres: img_tres,
    Precio_Unitario: parseFloat(precio_Unitario),
    Stock: parseInt(stock, 10),
    Estado: estado
    });
    navigate('/');
};

return (
    <div className="container">
    <div className="card text-center p-3" style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '24px', boxShadow: '0 12px 12px rgba(0.5, 0.5, 0.5, 0.5)', width: '45rem', margin: 'auto', cursor: 'pointer', transition: 'all 0.5s' }}>
        <h3 className="card-title">Editar Producto</h3>
        <form onSubmit={actualizar} className="card-body">
        <div className="mb-3">
            <label className="form-label">Nombre</label>
            <input value={nombre_Producto} onChange={(e) => setNombre(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Departamento</label>
            <input value={departamento} onChange={(e) => setDepartamento(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Marca</label>
            <input value={marca} onChange={(e) => setMarca(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Especificaciones</label>
            <input value={especificaciones} onChange={(e) => setEspecificaciones(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Descripcion</label>
            <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} className="form-control"></textarea>
        </div>
        <div className="mb-3">
            <label className="form-label">Unidad De Medida</label>
            <input value={unidad_Medida} onChange={(e) => setUnidad_Medida(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Modelo</label>
            <input value={modelo} onChange={(e) => setModelo(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Imagen Uno</label>
            <input value={img_uno} onChange={(e) => setImgUno(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Imagen Dos</label>
            <input value={img_dos} onChange={(e) => setImgDos(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Imagen Tres</label>
            <input value={img_tres} onChange={(e) => setImgTres(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Precio Unitario</label>
            <input value={precio_Unitario} onChange={(e) => setPrecioUnitario(e.target.value)} type="number" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Iva</label>
            <input value={iva} onChange={(e) => setStock(e.target.value)} type="number" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Stock</label>
            <input value={stock} onChange={(e) => setStock(e.target.value)} type="number" className="form-control" />
        </div>
        {/* <div className="mb-3">
            <label className="form-label">Estado</label>
            <input value={estado} onChange={(e) => setEstado(e.target.value)} type="text" className="form-control" />
        </div> */}
        <button type="submit" className="btn btn-primary">Actualizar</button>
        </form>
    </div>
    </div>
);
}

export default CompEditProducto;
