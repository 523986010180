import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TruncateText from '../trunk/trunk.js';

const URI = 'https://www.teknenl.lol/producto/';

const CompMostrarUnProducto = () => {
    const [producto, setProducto] = useState([]);
    const [search, setSearch] = useState("");

    const mostrar = async (query = "") => {
        const searchURI = query ? `${URI}${query}` : URI;
        const res = await fetch(searchURI);
        const data = await res.json();
        setProducto(data);
    }

    const searcher = (e) => {
        const value = e.target.value;
        setSearch(value);
        mostrar(value);
    }

    useEffect(() => {
        mostrar();
    }, []);

    const deleteProducto = async (id) => {
        if (window.confirm("¿Estás seguro de que quieres eliminar este producto?")) {
            await axios.delete(`${URI}${id}`);
            mostrar();
        }
    }
    // const styles = {
    //     maxWidth: '20rem',
    //     grap: '16px'
    // };

    const styleCard = {
        // backgroundColor: '#f3f3f3',
        padding: 6,
        borderRadius: '16px',
        boxShadow: '0 6px 18px rgba(0.2, 0.2, 0.2, 1)',
        maxWidth: '30rem'
    };
    return (
<div>
    <h1 className='texto'>Productos</h1>
    <nav className="navbar">
        <div className="container-fluid">
            <form className="d-flex ms-auto col-md-5 mb-3">
                <input 
                    value={search} 
                    onChange={searcher} 
                    className="form-control me-2" 
                    type="search" 
                    style={styleCard}
                    placeholder="Buscar..." 
                    aria-label="Search" 
                />
                <Link to={`/crear/`} style={{borderRadius: '12px', boxShadow: '0 8px 6px rgba(0.5, 0.5, 0.5, 0.5)',padding: 12}} className='btn btn-info me-5'><i className="fa-solid fa-square-plus"></i></Link>
            </form>
        </div>
    </nav>
    <div className='container fondo'>
        <div className='row'>
            {producto.length > 0 ? (
                producto.map((product) => (
                    <div className='col-lg-4 px-md-2 col-md-3 mb-3'style={styleCard} key={product.Id_Producto}>
                        <div className='card'>
                            <div className='card-body'>
                                <h5 className='card-title'><TruncateText text={product.Nombre_Producto} maxLength={20} /></h5>
                                <p className='card-text'><strong>Departamento:</strong> {product.Departamento}</p>
                                <p className='card-text'><strong>Marca:</strong> {product.Marca}</p>
                                <p className='card-text'><strong>Especificaciones:</strong> <TruncateText text={product.Especificaciones} maxLength={40} /></p>
                                <p className='card-text'><strong>Descripción:</strong> <TruncateText text={product.Descripcion} maxLength={40} /></p>
                                <p className='card-text'><strong>Unidad de Medida:</strong> {product.Unidad_Medida}</p>
                                <p className='card-text'><strong>Modelo:</strong> {product.Modelo}</p>
                                <Link to={`/edit/${product.Id_Producto}`} className='btn btn-info me-2'><i className='fa-solid fa-pen-to-square'></i> Editar</Link>
                                <button onClick={() => deleteProducto(product.Id_Producto)} className='btn btn-danger'><i className='fa-solid fa-trash-can'></i> Eliminar</button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className='col-12'>
                    <div className="alert alert-warning text-center" role="alert">
                        Producto no encontrado
                    </div>
                </div>
            )}
        </div>
    </div>
</div>

    );
}

export default CompMostrarUnProducto;
