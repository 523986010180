import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function SidebarAdmin({ isMaxSidebar, isMiniSidebar, isDarkMode, toggleSidebar, toggleDarkMode }) {
    const [correo, setCorreo] = useState('');
    const navigate = useNavigate();


    const obtenerCorreoDelToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const parsearToken = parseJwt(token);
            if (parsearToken) {
                setCorreo(parsearToken.correo);
                console.log('Correo del token:', parsearToken.correo);
            } else {
                console.log('El token no es válido. Por favor, regístrate.');
            }
        } else {
            console.log('No se encontró ningún token. Por favor, regístrate.');
        }
    };

    useEffect(() => {
        obtenerCorreoDelToken();

        const handleStorageChange = (event) => {
            if (event.key === 'token') {
                obtenerCorreoDelToken();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    const handleLogout = () => {
        localStorage.clear()
        console.log('Usuario deslogueado');
        window.location.reload();
        navigate('/login')
    };

    return (
        <div className={`barra-lateral ${isMaxSidebar ? 'max-barra-lateral' : ''} ${isMiniSidebar ? 'mini-barra-lateral' : ''}`}>
            <div>
                <div className="nombre-pagina">
                    <div>
                        <i id="cloud" name="cloud-outline" className="fa-brands fa-web-awesome">
                            <span>Tekne Solution</span>
                        </i>
                    </div>
                </div>
                <button className="boton">
                    <Link to="/" className="btn">
                        <i className="fa-solid fa-house"></i>
                        <span>Inicio</span>
                    </Link>
                </button>
            </div>
            <nav className="navegacion">
                <ul>
                    <li>
                        <Link to="mostrarproductos">
                            <i className="fa-solid fa-shop">
                                <span>Productos</span>
                            </i>
                        </Link>
                    </li>
                    <li>
                        <Link to="/crear">
                        <i className="fa-solid fa-square-plus">
                                <span>Agregar producto</span>
                            </i>
                        </Link>
                    </li>
                    <li>
                        <Link to="/pedidos">
                            <i className="fa-brands fa-shopify">
                                <span>Compras</span>
                            </i>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/comprar">
                            <i className="fa-solid fa-cart-plus">
                                <span>Carrito</span>
                            </i>
                        </Link>
                    </li> */}
                    {/* <li>
                        <Link to="/trash">
                            <i className="fa-solid fa-user-tie">
                                <span>Cuenta</span>
                            </i>
                        </Link>
                    </li> */}
                    {/* <li>
                        <Link to="/trash">
                            <i className="fa-solid fa-gear">
                                <span>Configuracion</span>
                            </i>
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/reporte">
                        <i class="fa-solid fa-chart-line">
                                <span>Balance</span>
                            </i>
                        </Link>
                    </li>
                </ul>
            </nav>

            <div>
                <div className="linea"></div>

                <div className="modo-oscuro">
                    <div className="info">
                        <ion-icon name="moon-outline"></ion-icon>
                        <span>Dark Mode</span>
                    </div>
                    <div className="switch" onClick={toggleDarkMode}>
                        <div className="base">
                            <div className={`circulo ${isDarkMode ? 'prendido' : ''}`}></div>
                        </div>
                    </div>
                </div>

                <div className="usuario">
                    <img
                        src="https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/corporate-user-icon.png"
                        alt="User avatar"
                    />
                    <div className="info-usuario">
                        <div className="nombre-email">
                            {correo ? (
                                <>
                                    <span className="email">{correo}</span>
                                    <button className="btn btn-danger mt-2" onClick={handleLogout}>Cerrar sesión</button>
                                </>
                            ) : (
                                <Link to="/login">
                                    <span className="email btn nombre btn-secondary">Iniciar sesión</span>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarAdmin;
