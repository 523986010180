import axios from 'axios';
import { useState, useEffect } from 'react';
// import  { Link } from 'react-router-dom';
import TruncateText from '../trunk/trunk';

const URI = 'https://www.teknenl.lol/producto/'

const CompMostrarProducto = () =>{
    const [producto, setProducto] = useState([])
    useEffect(() =>{
        getProducto()
    }, [])

    //mostrar
    const getProducto = async () => {
        const res = await axios.get(URI)
        setProducto(res.data)
    }

    //eliminar
    // const deleteProducto = async (id) => {
    //     if (window.confirm("¿Estás seguro de que quieres eliminar este producto?")) {
    //         await axios.delete(`${URI}${id}`);
    //         getProducto();
    //     }
    // }

    return (
    <div className='container'>
        <div className='row'>
            {producto.map((product) => (
                <div className='col-md-4 mb-3' key={product.Id_Producto}>
                    <div className='card'>
                        <div className='card-body'>
                            <h5 className='card-title'><TruncateText text={product.Nombre_Producto} maxLength={20} /></h5>
                            <p className='card-text'><strong>Departamento:</strong> {product.Departamento}</p>
                            <p className='card-text'><strong>Marca:</strong> {product.Marca}</p>
                            <p className='card-text'><strong>Especificaciones:</strong> <TruncateText text={product.Especificaciones} maxLength={50} /></p>
                            <p className='card-text'><strong>Descripción:</strong> <TruncateText text={product.Descripcion} maxLength={50} /></p>
                            <p className='card-text'><strong>Unidad de Medida:</strong> {product.Unidad_Medida}</p>
                            <p className='card-text'><strong>Modelo:</strong> {product.Modelo}</p>
                            {/* <Link to={`/edit/${product.Id_Producto}`} className='btn btn-info me-2'><i className='fa-solid fa-pen-to-square'></i> Editar</Link> */}
                            {/* <Link to={`/add/${product.Id_Producto}`} className='btn btn-info me-2'><i className="fa-solid fa-cart-shopping"></i></Link> */}
                            {/* <button onClick={() => deleteProducto(product.Id_Producto)} className='btn btn-danger'><i className='fa-solid fa-trash-can'></i> Eliminar</button> */}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
    );    
}

export default CompMostrarProducto