import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const URI = 'https://www.teknenl.lol/direccion/';

axios.defaults.withCredentials = true;

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
const CompEditDireccion = () => {
const [calle, setCalle] = useState('');
const [colonia, setColonia] = useState('');
const [numExt, setNumExt] = useState('');
const [numInt, setNumInt] = useState('');
const [codigoPostal, setCodigoPostal] = useState('');
const [showModal, setShowModal] = useState(false);
const navigate = useNavigate();
const token = parseJwt(localStorage.getItem('token'))
const id = token.direccion


useEffect(() => {
    getDireccionById();
}, [id]);

const getDireccionById = async () => {
    try {

    const res = await axios.get(`${URI}${id}`);
    if (res.data) {
        setCalle(res.data.Calle || '');
        setColonia(res.data.Colonia || '');
        setNumExt(res.data.Num_Ext || '');
        setNumInt(res.data.Num_Int || '');
        setCodigoPostal(res.data.Codigo_Postal || '');
    }
    } catch (error) {
    console.error(error);
    }
};

const actualizar = async (e) => {
    e.preventDefault();
    await axios.put(`${URI}${id}`, {
    Calle: calle,
    Colonia: colonia,
    Num_Ext: numExt,
    Num_Int: numInt,
    Codigo_Postal: codigoPostal,
    });
    setShowModal(true);
};

const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
};

return (
    <div className="container">
    <div className="card text-center p-3" style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '24px', boxShadow: '0 12px 12px rgba(0.5, 0.5, 0.5, 0.5)', width: '45rem', margin: 'auto', cursor: 'pointer', transition: 'all 0.5s' }}>
        <h3 className="card-title">Direccion</h3>
        <form onSubmit={actualizar} className="card-body">
        <div className="mb-3">
            <label className="form-label">Calle</label>
            <input value={calle} onChange={(e) => setCalle(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Colonia</label>
            <input value={colonia} onChange={(e) => setColonia(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Número Exterior</label>
            <input value={numExt} onChange={(e) => setNumExt(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Número Interior</label>
            <input value={numInt} onChange={(e) => setNumInt(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Código Postal</label>
            <input value={codigoPostal} onChange={(e) => setCodigoPostal(e.target.value)} type="text" className="form-control" />
        </div>
        <button type="submit" className="btn btn-primary">Actualizar</button>
        </form>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        <Modal.Title>Dirección Actualizada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        La dirección se ha actualizado correctamente.
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
        </Button>
        </Modal.Footer>
    </Modal>
    </div>
);
}

export default CompEditDireccion;
