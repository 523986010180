import React, { useEffect, useRef } from 'react';

const PaypalButton = () => {
    const paypalRef = useRef();

    useEffect(() => {
        const addPaypalScript = async () => {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID`;
            script.async = true;
            script.onload = () => {
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: '0.01' // Can reference state or props to get the amount
                                }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then(details => {
                            alert('Transaction completed by ' + details.payer.name.given_name);
                            // OPTIONAL: Call your server to save the transaction
                        });
                    }
                }).render(paypalRef.current);
            };
            document.body.appendChild(script);
        };

        if (!window.paypal) {
            addPaypalScript();
        } else {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: '0.01' // Can reference state or props to get the amount
                            }
                        }]
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(details => {
                        alert('Transaction completed by ' + details.payer.name.given_name);
                        // OPTIONAL: Call your server to save the transaction
                    });
                }
            }).render(paypalRef.current);
        }
    }, []);

    return (
        <div>
            <div ref={paypalRef}></div>
        </div>
    );
};

export default PaypalButton;
