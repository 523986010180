import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TruncateText from '../trunk/trunk';
// import './CompoMostrarPedidos.css';  // Import custom CSS file for table styling

const URI = 'https://www.teknenl.lol/pedidos'

const CompoMostrarPedidos = () => {
    const [pedidos, setPedidos] = useState([])
    useEffect(() => {
        getProducto()
    }, [])

    // mostrar
    const getProducto = async () => {
        const res = await axios.get(URI)
        setPedidos(res.data)
        // console.log(res.data)
    }
    const styles = {
        table: {
        width: '100%',
        marginBottom: '1rem',
        color: '#212529',
        borderCollapse: 'collapse',
        },
        tableBordered: {
        border: '1px solid #dee2e6',
        },
        tableHover: {
        transition: 'background-color 0.3s',
        },
        tableRowHover: {
        backgroundColor: '#f5f5f5',
        },
        tableTransitions: {
        transition: 'background-color 0.3s',
        },
        tableShadow: {
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        },
    };

    return (
        <div className='container'>
            <table style={{ ...styles.table, ...styles.tableShadow, ...styles.tableTransitions }} className='table table-bordered table-hover table-transitions table-shadow'>
                <thead>
                    <tr>
                        <th scope='col'>Pedido No</th>
                        <th scope='col'>Nombre</th>
                        {/* <th scope='col'>Apellido</th> */}
                        <th scope='col'>Producto</th>
                        <th scope='col'>Cantidad</th>
                        <th scope='col'>Precio Unitario</th>
                        <th scope='col'>Total</th>
                        <th scope='col'>Estado</th>
                        {/* <th scope='col'>Acciones</th> */}
                    </tr>
                </thead>
                <tbody>
                    {pedidos.map((product) => (
                        <tr key={product.Pedido}>
                            <td>{product.Pedido}</td>
                            <td><TruncateText text={product.Nombre} maxLength={20} /></td>
                            {/* <td>{product.Apellido}</td> */}
                            <td>{product.Producto}</td>
                            <td><TruncateText text={product.Cantidad} maxLength={50} /></td>
                            <td>{product.Precio_Unitario}</td>
                            <td>{product.Cantidad * product.Precio_Unitario}</td>
                            <td>{product.Estado}</td>
                                {/* <td>
                                    <Link to={`/edit/${product.Id_Producto}`} className='btn btn-info me-2'><i className='fa-solid fa-pen-to-square'></i> Editar</Link>
                                    <Link to={`/add/${product.Id_Producto}`} className='btn btn-info me-2'><i className="fa-solid fa-cart-shopping"></i></Link>
                                    <button onClick={() => deleteProducto(product.Id_Producto)} className='btn btn-danger'><i className='fa-solid fa-trash-can'></i> Eliminar</button>
                                </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
export default CompoMostrarPedidos;
