const TruncateText = ({ text, maxLength }) => {
    if (text && text.length) {
        if (text.length <= maxLength) {
            return text;
        } else {
            return `${text.substring(0, maxLength)}...`;
        }
    } else {
        return "-";
    }
};


export default TruncateText