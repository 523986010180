// import logo from './logo.svg';
import './App.css';


import React, { useEffect, useState,useCallback } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
// import { Navbar, Nav } from 'react-bootstrap';
//importacion de componentes
import Sidebar from './menu';
import SidebarAdmin from './menuAdmin'

import CompMostrarProducto from './Administrador/ProductosAdm/mostrarProductos';
import CompCrearProducto from './Administrador/ProductosAdm/createProducto';
import CompEditProducto from './Administrador/ProductosAdm/edithProducto';
// import Login from './min/Login';
import Login from './LoginUsuarios/Login';
import CompMostrarUnProducto from './Administrador/compBusqueda/busqueda';
import CompoMostrarPedidos from './Administrador/Pedidos/mostrarPedidos'
import Registrar from './LoginUsuarios/Registrar';
//Proteccion de rutas por roles de administrador y usuario
import ProtectedRoute from './ProtectedRoute';
import PaypalButton from './Administrador/PaypalButton';
import ProtectedUserRoute from './ProtectedUserRoute';
import CompReporte from './Administrador/Reportes/CompVentasMes';
import CompMostrarUnInventario from './compBusqueda/busquedaInventario';
import CompAbrirProductoInventario from './compBusqueda/abrirProductoInventario';
import CompEditDireccion from './compDir/CompDireccion';
import CompMostrarCarrito from './comCarrito/comCarrito';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
function App() {
  const [isMaxSidebar, setIsMaxSidebar] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);
  const [user, setUser] = useState(null);


  const toggleSidebar = useCallback(() => {
    setIsMaxSidebar(prevState => !prevState);
    setIsMiniSidebar(prevIsMaxSidebar => window.innerWidth <= 320 ? !prevIsMaxSidebar : false);
  }, [setIsMaxSidebar]); 

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {

    try {
      const token = localStorage.getItem('token');
      if (token) {
        const { role } = parseJwt(token);
        setUser(role); //obtener rol
      }
    } catch (error) {
      console.log(error);
    }

    const body = document.body;
    if (isDarkMode) {
      body.classList.add('dark-mode');
    } else {
      body.classList.remove('dark-mode');
    }
    const cloud = document.getElementById("cloud");
    const barraLateral = document.querySelector(".barra-lateral");
    const spans = document.querySelectorAll("span");
    const palanca = document.querySelector(".switch");
    const circulo = document.querySelector(".circulo");
    const menu = document.querySelector(".menu");
    const main = document.querySelector("main");
  
    const handleMenuClick = (e) => {
      e.stopPropagation();
      toggleSidebar();
      barraLateral.classList.toggle("max-barra-lateral");
      if (barraLateral.classList.contains("max-barra-lateral")) {
        menu.children[0].style.display = "none";
        menu.children[1].style.display = "block";
      } else {
        menu.children[0].style.display = "block";
        menu.children[1].style.display = "none";
      }
      if (window.innerWidth <= 320) {
        barraLateral.classList.add("mini-barra-lateral");
        main.classList.add("min-main");
        spans.forEach((span) => {
          span.classList.add("oculto");
        });
      }
    };
  
    const handlePalancaClick = () => {
      body.classList.toggle("dark-mode");
      body.classList.toggle("f");
      circulo.classList.toggle("prendido");
    };
  
    const handleCloudClick = () => {
      barraLateral.classList.toggle("mini-barra-lateral");
      main.classList.toggle("min-main");
      spans.forEach((span) => {
        span.classList.toggle("oculto");
      });
    };
  
    menu.addEventListener("click", handleMenuClick);
    palanca.addEventListener("click", handlePalancaClick);
    cloud.addEventListener("click", handleCloudClick);
  
    return () => {
      menu.removeEventListener("click", handleMenuClick);
      palanca.removeEventListener("click", handlePalancaClick);
      cloud.removeEventListener("click", handleCloudClick);
    };
  }, [isDarkMode, toggleSidebar]);
  

  return (
    <BrowserRouter>
      <div className={`App ${isDarkMode ? "dark-mode" : "false"}`}>
        <div className="menu" onClick={toggleSidebar}>
          <i className="fa-solid fa-face-surprise" name={isMaxSidebar ? "close-outline" : "menu-outline"}></i>
          <i className="fa-regular fa-face-frown-open"></i>
        </div>
        {/* menu dependiendo el rol */}
        {user === 2147483647 ? (
          <SidebarAdmin
            isMaxSidebar={isMaxSidebar}
            isMiniSidebar={isMiniSidebar}
            isDarkMode={isDarkMode}
            toggleSidebar={toggleSidebar}
            toggleDarkMode={toggleDarkMode}
          />
        ) : (
          <Sidebar
            isMaxSidebar={isMaxSidebar}
            isMiniSidebar={isMiniSidebar}
            isDarkMode={isDarkMode}
            toggleSidebar={toggleSidebar}
            toggleDarkMode={toggleDarkMode}
          />
        )}

        <main>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/sing' element={<Registrar />} />
            <Route path='/carrito' element ={<CompMostrarCarrito/>}/>
            <Route path='/reporte' element={ <ProtectedRoute> <CompReporte /></ProtectedRoute>} />
            <Route path='/abrir/:id' element={<CompAbrirProductoInventario />} />
            <Route path='/' element={<CompMostrarUnInventario />}/>
            <Route path='/productos' element={<CompMostrarProducto />} />
            
            <Route path='direccion' element={
            <ProtectedUserRoute> 
              <CompEditDireccion/>
            </ProtectedUserRoute>}/>
            <Route path='/crear' element={
              <ProtectedRoute>
                <CompCrearProducto />
              </ProtectedRoute>
            } />
            <Route path='/edit/:id' element={
              <ProtectedRoute>
                <CompEditProducto />
              </ProtectedRoute>
            } />
            <Route path='/mostrarproductos' element={
              <ProtectedRoute >
                <CompMostrarUnProducto />
              </ProtectedRoute>
            } />
            <Route path='/pedidos' element={
              <ProtectedRoute>
                <CompoMostrarPedidos />
              </ProtectedRoute>
            } />
            <Route path='/comprar' element={
              <div><h1>comprar</h1><PaypalButton/></div>
            }></Route>
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;