import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const URI = 'https://www.teknenl.lol/producto/';

const CompCrearProducto = () => {
const [nombre_Producto, setNombre] = useState('');
const [departamento, setDepartamento] = useState('');
const [marca, setMarca] = useState('');
const [especificaciones, setEspecificaciones] = useState('');
const [descripcion, setDescripcion] = useState('');
const [unidad_Medida, setUnidad_Medida] = useState('');
const [modelo, setModelo] = useState('');
const [img_uno, setImgUno] = useState('');
const [img_dos, setImgDos] = useState('');
const [img_tres, setImgTres] = useState('');
const [precio_Unitario, setPrecioUnitario] = useState('');
const [stock, setStock] = useState('');
const [iva, setIva] = useState('');
const [showAlert, setShowAlert] = useState(false);
const navigate = useNavigate();

const almacenar = async (e) => {
    e.preventDefault();

    if (
    nombre_Producto.trim() === '' ||
    departamento.trim() === '' ||
    marca.trim() === '' ||
    especificaciones.trim() === '' ||
    descripcion.trim() === '' ||
    unidad_Medida.trim() === '' ||
    modelo.trim() === '' ||
    img_uno.trim() === '' ||
    img_dos.trim() === '' ||
    img_tres.trim() === '' ||
    precio_Unitario.trim() === '' ||
    stock.trim() === '' ||
    iva.trim() === ''
    ) {
    console.log('Error en la llenada de los campos');
    setShowAlert(true);
    return;
    }

    try {
    await axios.post(URI, {
        Nombre_Producto: nombre_Producto,
        Departamento: departamento,
        Marca: marca,
        Especificaciones: especificaciones,
        Descripcion: descripcion,
        Unidad_Medida: unidad_Medida,
        Modelo: modelo,
        Img_uno: img_uno,
        Img_dos: img_dos,
        Img_tres: img_tres,
        Estado: 'Activo',
        Precio_Unitario: parseFloat(precio_Unitario),
        Stock: parseInt(stock, 10),
        Fecha_Ingreso: new Date().toISOString(),
        Iva: parseFloat(iva)
    });
    navigate('/');
    } catch (error) {
    console.error('Error al enviar la solicitud:', error);
    }
};

const styleCard = {
    backgroundColor: 'rgb(245, 245, 245)',
    padding: 8,
    borderRadius: '16px',
    boxShadow: '0 12px 12px rgba(0.5, 0.5, 0.5, 0.5)',
};

const styleContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
};

return (
    <div className="row justify-content-center" style={styleContainer}>
    <form onSubmit={almacenar} className="col-md-6">
        <div className='col-md-12 mb-4'>
        <div className="card" style={styleCard}>
            <div className="card-body">
            {showAlert && (
                <div className="alert alert-warning" role="alert">
                Por favor, complete todos los campos antes de enviar el formulario.
                </div>
            )}
            <h3 className='card-title'>Producto</h3>
            <div className="mb-3 row">
                <label className="form-label">Nombre</label>
                <input value={nombre_Producto} onChange={(e) => setNombre(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3 row">
                <label className="form-label">Departamento</label>
                <input value={departamento} onChange={(e) => setDepartamento(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Marca</label>
                <input value={marca} onChange={(e) => setMarca(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Especificaciones</label>
                <input value={especificaciones} onChange={(e) => setEspecificaciones(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Descripcion</label>
                <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Unidad De Medida</label>
                <input value={unidad_Medida} onChange={(e) => setUnidad_Medida(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Modelo</label>
                <input value={modelo} onChange={(e) => setModelo(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Imagen Uno</label>
                <input value={img_uno} onChange={(e) => setImgUno(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Imagen Dos</label>
                <input value={img_dos} onChange={(e) => setImgDos(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Imagen Tres</label>
                <input value={img_tres} onChange={(e) => setImgTres(e.target.value)} type="text" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Precio Unitario</label>
                <input value={precio_Unitario} onChange={(e) => setPrecioUnitario(e.target.value)} type="number" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">Stock</label>
                <input value={stock} onChange={(e) => setStock(e.target.value)} type="number" className="form-control" />
            </div>
            <div className="mb-3">
                <label className="form-label">IVA</label>
                <input value={iva} onChange={(e) => setIva(e.target.value)} type="number" className="form-control" />
            </div>
            </div>
            <button type="submit" className="btn btn-primary">Agregar</button>
        </div>
        </div>
    </form>
    </div>
);
}

export default CompCrearProducto;
