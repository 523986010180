import axios from "axios";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const URI = 'https://www.teknenl.lol/user/';
const Registrar = () => {
    const [Nombre, setNombre] = useState('');
    const [Apellido, setApellido] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [Correo, setCorreo] = useState('');
    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [Calle, setCalle] = useState('');
    const [Colonia, setColonia] = useState('');
    const [NumExt, setNumExt] = useState('0');
    const [NumInt, setNumInt] = useState('0');
    const [CodigoPostal, setCodigoPostal] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertPass, setShowAlertPass] = useState(false);
    const navigate = useNavigate();

    const almacenar = async (e) => {
        e.preventDefault();

        if (
            Nombre.trim() === '' ||
            Apellido.trim() === '' ||
            Telefono.trim() === '' ||
            Password.trim() === '' ||
            Correo.trim() === '' ||
            Calle.trim() === '' ||
            Colonia.trim() === '' ||
            CodigoPostal.trim() === ''
        ) {
            setShowAlert(true);
            return;
        }

        if (Password !== confirmPassword) {
            setShowAlertPass(true);
            return;
        }

        try {
            await axios.post(URI, {
                Nombre,
                Apellido,
                Telefono,
                Correo,
                Password,
                Estado_Cuenta: 1,
                Id_Rol: 2,
                Calle,
                Colonia,
                NumExt,
                NumInt,
                CodigoPostal
            });
            navigate('/login');
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    const styleCard = {
        backgroundColor: 'rgb(245, 245, 245)',
        padding: 8,
        borderRadius: '16px',
        boxShadow: '0 12px 12px rgba(0.5, 0.5, 0.5, 0.5)',
    };

    const styleContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    };

    return (
        <>
            <div className="row justify-content-center" style={styleContainer}>
                <form onSubmit={almacenar} className="col-md-6">
                    <div className='col-md-12 mb-4'>
                        <div className="card" style={styleCard}>
                            <div className="card-body">
                                {showAlert && (
                                    <div className="alert alert-warning" role="alert">
                                        Por favor, complete todos los campos antes de enviar el formulario.
                                    </div>
                                )}
                                {showAlertPass && (
                                    <div className="alert alert-warning" role="alert">
                                        Las contraseñas deben de ser iguales
                                    </div>
                                )}
                                <h3 className='card-title'>Registrar</h3>
                                <div className="mb-3 row">
                                    <label className="form-label">Nombre</label>
                                    <input value={Nombre} onChange={(e) => setNombre(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3 row">
                                    <label className="form-label">Apellido</label>
                                    <input value={Apellido} onChange={(e) => setApellido(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Telefono</label>
                                    <input value={Telefono} onChange={(e) => setTelefono(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Calle</label>
                                    <input value={Calle} onChange={(e) => setCalle(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Colonia</label>
                                    <input value={Colonia} onChange={(e) => setColonia(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Num. Ext</label>
                                    <input value={NumExt} onChange={(e) => setNumExt(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Num. Int</label>
                                    <input value={NumInt} onChange={(e) => setNumInt(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Codigo postal</label>
                                    <input value={CodigoPostal} onChange={(e) => setCodigoPostal(e.target.value)} type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Correo</label>
                                    <input value={Correo} onChange={(e) => setCorreo(e.target.value)} type="email" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Contraseña</label>
                                    <input value={Password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Confirmar Contraseña</label>
                                    <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control"/>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <button type="submit" className="btn btn-primary col-md-5 me-3">Agregar</button>
                                <Link to="/login" className='btn col-md-5'>Ingresar</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Registrar;
