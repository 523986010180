import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import format from 'date-fns/format';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const CompReporte = () => {
    const currentDate = new Date();
    const formattedDateYear = format(currentDate, 'yyyy');
    const formattedDateMonth = format(currentDate, 'MM');
    const formattedDateDay = format(currentDate, 'dd');
    const [dia, setDia] = useState(formattedDateDay);
    const [mes, setMes] = useState(formattedDateMonth);
    const [ano, setAno] = useState(formattedDateYear);
    const [chartData, setChartData] = useState({});
    const [chartDataDay, setChartDataDay] = useState({});
    const [fecha, setFecha] = useState(new Date(formattedDateYear, formattedDateMonth - 1, formattedDateDay));
    const [reporte, setReporte] = useState([]);
    const [reporteDia, setReporteDia] = useState([]);
    
    const handleDateChange = date => {
        setFecha(date);
        setMes(date.getMonth() + 1);
        setAno(date.getFullYear());
        setDia(date.getDate());
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                position: 'top',
            },
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const datos = { mes, ano };

            try {
                const fechaEspecifica = `${ano}-${mes}-${dia}`;
                const responseDia = await fetch('https://www.teknenl.lol/reporte/dia/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ fecha: fechaEspecifica }),
                });

                const dataDia = await responseDia.json();
                setReporteDia(dataDia);

                if (Array.isArray(dataDia) && dataDia.length > 0) {
                    const Departamento = dataDia.map(item => item.Departamento);
                    const Cantidad = dataDia.map(item => item.
                        Total_Cantidad
                        );
                    const Ganancia = dataDia.map(item => item.Total_Ganancia)
                    setChartDataDay({
                        labels: Departamento,
                        datasets: [
                            {
                                label: 'Productos vendidos',
                                data: Cantidad,
                                
                                backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(201, 203, 207, 0.2)',
                                'rgba(128, 133, 233, 0.2)',
                                'rgba(255, 99, 132, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(201, 203, 207, 1)',
                                    'rgba(128, 133, 233, 1)',
                                    'rgba(255, 99, 132, 1)'
                                ],
                                borderWidth: 2
                            },
                            {
                                label: 'Total',
                                data: Ganancia,
                                backgroundColor: [
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(201, 203, 207, 0.2)',
                                    'rgba(128, 133, 233, 0.2)',
                                    'rgba(255, 99, 132, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)',
                                    'rgba(201, 203, 207, 1)',
                                    'rgba(128, 133, 233, 1)',
                                    'rgba(255, 99, 132, 1)'
                                ],
                                borderWidth: 2
                            }
                        ]
                    });
                } else {
                    // console.log("No se encontraron datos en la respuesta del día.");
                    setChartDataDay({});
                }
            } catch (error) {
                console.error('Error en la solicitud del día:', error);
                setChartDataDay({});
            }

            try {
                const response = await fetch('https://www.teknenl.lol/reporte/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(datos),
                });

                const data = await response.json();
                setReporte(data);

                if (Array.isArray(data) && data.length > 0) {
                    const Departamento = data.map(item => item.Departamento);
                    const ProductosVendidos = data.map(item => item.Total_Productos_Vendidos);
                    const Ganancias = data.map(item => item.Total_Ganancias);

                    setChartData({
                        labels: Departamento,
                        datasets: [
                            {
                                label: 'Productos Vendidos',
                                data: ProductosVendidos,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(201, 203, 207, 0.2)',
                                    'rgba(128, 133, 233, 0.2)',
                                    'rgba(255, 99, 132, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(201, 203, 207, 1)',
                                    'rgba(128, 133, 233, 1)',
                                    'rgba(255, 99, 132, 1)'
                                ],
                                borderWidth: 2
                            },
                            {
                                label: 'Total',
                                data: Ganancias,
                                backgroundColor: [
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(201, 203, 207, 0.2)',
                                    'rgba(128, 133, 233, 0.2)',
                                    'rgba(255, 99, 132, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)',
                                    'rgba(201, 203, 207, 1)',
                                    'rgba(128, 133, 233, 1)',
                                    'rgba(255, 99, 132, 1)'
                                ],
                                borderWidth: 2
                            }
                        ]
                    });
                } else {
                    // console.log("No se encontraron datos en la respuesta del mes.");
                    setChartData({});
                }
            } catch (error) {
                console.error('Error en la solicitud del mes:', error);
                setChartData({});
            }
        };

        fetchData();
    }, [mes, ano, dia]);

    const styleContainer = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };
    const styleCenter = {
        width: '350px',
        textAlign: 'center',
        marginBottom: '30px'
    };
    const styles = {
        datepickerContainer: {
            display: 'inline-block',
            marginBottom: 30,
        },
    };
    const styleCard = {
        backgroundColor: '#f3f3f3',
        padding: 4,
        borderRadius: '24px',
        boxShadow: '0 8px 6px rgba(0.5, 0.5, 0.5, 0.5)',
        marginBottom: '35px'
    };

    return (
        <div style={styleContainer}>
            <div style={styleCenter}>
                <div style={styles.datepickerContainer}>
                    <h3 className="texto">Reporte del Mes</h3>
                    <DatePicker selected={fecha} onChange={handleDateChange} />
                    <br />
                </div>
                
                <div>
                    <div className="card" style={styleCard}>
                        <div className="card-body">
                            {chartData && chartData.labels && chartData.labels.length > 0 ? (
                                <Pie data={chartData} options={options} />
                            ) : (
                                <p style={styles.datepickerContainer}>No hay datos para mostrar en este mes</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={styleCenter}>
                <h3 className="texto">Reporte del Día</h3>
                <div className="card" style={styleCard}>
                    <div className="card-body">
                        {chartDataDay && chartDataDay.labels && chartDataDay.labels.length > 0 ? (
                            <Pie data={chartDataDay} options={options} />
                        ) : (
                            <p style={styles.datepickerContainer}>No hay datos para mostrar en la fecha {mes}/{dia}/{ano}.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompReporte;
