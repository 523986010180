import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const URI = 'https://www.teknenl.lol/inventario';
axios.defaults.withCredentials = true;

const CompAbrirProductoInventario = () => {
    const [producto, setProducto] = useState({});
    const { id } = useParams();

    useEffect(() => {
        getProductoById();
    }, [id]);

    const getProductoById = async () => {
        try {
            const res = await axios.get(`${URI}/id/${id}`);
            if (res.data) {
                setProducto(res.data);
            }
        } catch (error) {
            console.error("Error al obtener el producto:", error);
        }
    };

    const styleCard = {
        padding: '20px',
        borderRadius: '16px',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
        maxWidth: '90%',
        margin: 'auto',
        marginTop: '20px',
        backgroundColor: '#fff',
        border: '3px outset black'
    };

    const styleImg = {
        width: '200px',
        height: 'auto',
        objectFit: 'cover',
    };

    const styleContainer = {
        filter: 'drop-shadow(1px 1px 20px green)'
    };

    const handleBuyNow = () => {
        const paypalUrl = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=perezangel2015@gmail.com&item_name=${producto.Nombre_Producto}&amount=${producto.Precio_Unitario}&currency_code=MXN&return=https://tekneonlinenl.org/&cancel_return=https://tekneonlinenl.org/`;
        window.location.href = paypalUrl;
    };

    return (
        <>
            <div className="d-flex justify-content-end m-3">
                <Link to='/' className="btn btn-success">Regresar</Link>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col md={7}>
                        <div className="producto-card card" style={styleCard}>
                            {producto.Id_Inventario > 0 ? (
                                <>
                                    <h1 className="producto-titulo text-center mb-4">{producto.Nombre_Producto}</h1>
                                    <Carousel style={styleContainer}>
                                        {producto.Img_uno && (
                                            <Carousel.Item>
                                                <img style={styleImg} src={producto.Img_uno} alt="Imagen 1" />
                                            </Carousel.Item>
                                        )}
                                        {producto.Img_dos && (
                                            <Carousel.Item>
                                                <img style={styleImg} src={producto.Img_dos} alt="Imagen 2" />
                                            </Carousel.Item>
                                        )}
                                        {producto.Img_tres && (
                                            <Carousel.Item>
                                                <img style={styleImg} src={producto.Img_tres} alt="Imagen 3" />
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                    <div className="producto-detalles mt-4">
                                        <p><strong>Departamento:</strong> {producto.Departamento}</p>
                                        <p><strong>Marca:</strong> {producto.Marca}</p>
                                        <p><strong>Modelo:</strong> {producto.Modelo}</p>
                                        <p><strong>Especificaciones:</strong> {producto.Especificaciones}</p>
                                        <p><strong>Descripción:</strong> {producto.Descripcion}</p>
                                        <p><strong>Stock:</strong> {producto.Stock} unidades</p>
                                        <p className="producto-precio"><strong>Precio:</strong> ${producto.Precio_Unitario} {producto.Unidad_Medida}</p>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button 
                                            className="btn btn-primary"
                                            onClick={handleBuyNow}
                                        >
                                            Comprar con PayPal
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className='col-12'>
                                    <div className="alert alert-danger text-center" role="alert">
                                        Producto no disponible
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CompAbrirProductoInventario;
