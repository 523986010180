// import t from"react";import{Navigate as e}from"react-router-dom";function parseJwt(t){let e=t.split(".")[1],r=e.replace(/-/g,"+").replace(/_/g,"/"),o=decodeURIComponent(window.atob(r).split("").map(function(t){return"%"+("00"+t.charCodeAt(0).toString(16)).slice(-2)}).join(""));return JSON.parse(o)}function tokenExpirado(t){if(!t)return!0;let e=parseJwt(t),r=Math.floor(Date.now()/1e3);return e.exp<r}let ProtectedRoute=({children:t})=>{let e=localStorage.getItem("token"),r=localStorage.getItem("rol");return!e||tokenExpirado(e)||"2147483647"!==r?<e to="/login"/>:t};export default ProtectedRoute;
import React from 'react';
import { Navigate } from 'react-router-dom';

// Función para decodificar el token JWT
function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

// Función para verificar si el token ha expirado
function tokenExpirado(token) {
    if (!token) return true;

    const decodificarToken = parseJwt(token);
    const tiempoTranscurrido = Math.floor(Date.now() / 1000);
    return decodificarToken.exp < tiempoTranscurrido;
}

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    if (!token || tokenExpirado(token)) {
        localStorage.removeItem('token')
        return <Navigate to="/login" />;
    }

    const { role } = parseJwt(token);
    if (role !== 2147483647) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;


// import React from 'react';
// import { Navigate } from 'react-router-dom';

// // Función para decodificar el token JWT
// function parseJwt(token) {
//     const base64Url = token.split('.')[1];
//     const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
//     return JSON.parse(jsonPayload);
// }

// // Función para verificar si el token ha expirado
// function tokenExpirado(token) {
//     if (!token) return true;

//     const decodificarToken = parseJwt(token);
//     const tiempoTranscurrido = Math.floor(Date.now() / 1000);
//     return decodificarToken.exp < tiempoTranscurrido;
// }

// // Componente que protege las rutas basado en el token y el rol
// const ProtectedRoute = ({ children }) => {
//     const cookies = document.cookie;
//     const tokenCookie = cookies.split('; ').find(row => row.startsWith('token='));
//     const roleCookie = cookies.split('; ').find(row => row.startsWith('rol='));

//     // Verificar si las cookies del token y el rol están presentes
//     if (!tokenCookie || !roleCookie) {
//         return <Navigate to="/login" />;
//     }

//     const token = tokenCookie.split('=')[1];
//     const role = roleCookie.split('=')[1];

//     // Verificar si el token está presente, si ha expirado o si el rol es válido
//     if (!token || tokenExpirado(token) || role !== '2147483647') {
//         // En caso de condiciones no cumplidas, redirigir a la página de inicio de sesión
//         return <Navigate to="/login" />;
//     }

//     // Si el token es válido y el rol es correcto, renderizar el contenido protegido
//     return children;
// };

// export default ProtectedRoute;