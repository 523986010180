import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//jalo?
const URI = 'https://www.teknenl.lol/user/carrito/';
const URIUpdate = 'https://www.teknenl.lol/user/carrito/producto/';
const URIEliminar = 'https://www.teknenl.lol/user/carrito/eliminar/';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const CompMostrarCarrito = () => {
    const [producto, setProducto] = useState([]);
    const [quantities, setQuantities] = useState({});
    const navigate = useNavigate();

    var token = '', id;
    try {
        token = parseJwt(localStorage.getItem('token'));
        id = token.user;
    } catch (error) {
        console.log('No hay carrito disponible');
    }

    useEffect(() => {
        getProducto();
    }, []);

    const getProducto = async () => {
        try {
            const res = await axios.get(`${URI}${id}`);
            const datos = res.data;
            console.log(datos);

            setProducto(datos);

            const initialQuantities = datos.reduce((acc, product) => {
                acc[product.Id_Carrito] = product.Cantidad || 0;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        } catch (error) {
            console.log('Error al obtener los productos:', error);
        }
    };

    const updateQuantity = async (cartId, newQuantity) => {
        if (newQuantity === 0) {
            console.log('Cantidad no puede ser 0.');
            return;
        }

        try {
            await axios.put(`${URIUpdate}${cartId}`, {
                cantidad: newQuantity
            });
            console.log(`Cantidad actualizada para el carrito ${cartId} a ${newQuantity}`);
        } catch (error) {
            console.log('Error al actualizar la cantidad:', error);
        }
    };

    const eliminarProducto = async (cartId) => {
        try {
            await axios.delete(`${URIEliminar}${cartId}`);
            console.log(`Producto con Id_Carrito ${cartId} eliminado.`);
            getProducto();
            window.location.reload();
        } catch (error) {
            console.log('Error al eliminar el producto:', error);
        }
    };

    const handleQuantityChange = (cartId, newQuantity) => {
        if (newQuantity === 0) {
            console.log('Cantidad no puede ser 0.');
            return;
        }

        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [cartId]: newQuantity
        }));
        updateQuantity(cartId, newQuantity);
    };

    const handleCardClick = (productId) => {
        navigate(`/abrir/${productId}`);
    };

    return (
        <div className="container">
            {producto.length > 0 ? (
                producto.map((product) => (
                    <div 
                        key={product.Id_Carrito} 
                        className='btn producto-card' 
                        style={{ padding: '6px' }}
                        onClick={() => handleCardClick(product.Id_Producto)}
                    >
                        <div className="card">
                            <div className="card-header">
                                {product.Nombre_Producto}
                            </div>
                            <div className="card-body">
                                <blockquote className="blockquote mb-0">
                                    <p className='texto-carrito'></p>
                                    <footer className="blockquote-footer">
                                        {product.Descripcion}
                                        <cite title="Source Title"></cite>
                                    </footer>
                                    <div className='producto-precio' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input 
                                            style={{ borderRadius: '16px' }} 
                                            type="number"
                                            min="1"
                                            max="100"
                                            step="1"
                                            value={quantities[product.Id_Carrito]}
                                            onChange={(e) => handleQuantityChange(product.Id_Carrito, parseInt(e.target.value))}
                                            onClick={(e) => e.stopPropagation()} // Detiene la propagación del evento clic
                                        />
                                        <i className="fa-regular fa-trash-can" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); eliminarProducto(product.Id_Carrito); }}></i>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className='col-12'>
                    <div className="alert alert-warning text-center" role="alert">
                        No hay productos en tu carrito :(
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompMostrarCarrito;
