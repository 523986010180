import axios from 'axios';
import { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import TruncateText from '../trunk/trunk.js';

axios.defaults.withCredentials = true;

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const URI = 'https://www.teknenl.lol/inventario/';
const URIAdd = 'https://www.teknenl.lol/user/carrito/agregar/';

const CompMostrarUnInventario = () => {
    const [producto, setProducto] = useState([]);
    const [search, setSearch] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [addedProduct, setAddedProduct] = useState(null);
    
    let token, id;
    try {
        token = parseJwt(localStorage.getItem('token'));
        id = token.user;
    } catch (error) {
        console.error("Error parsing token", error);
    }

    const mostrar = async (query = "") => {
        const searchURI = query ? `${URI}${query}` : URI;
        const res = await fetch(searchURI);
        const data = await res.json();
        setProducto(data);
    };

    const searcher = (e) => {
        const value = e.target.value;
        setSearch(value);
        mostrar(value);
    };

    useEffect(() => {
        mostrar();
    }, []);

    const deleteProducto = async (id) => {
        if (window.confirm("¿Estás seguro de que quieres eliminar este producto?")) {
            await axios.delete(`${URI}${id}`);
            mostrar();
        }
    };

    const addToCart = async (idUsuario, idInventario, cantidad, product) => {
        try {
            const response = await axios.post(URIAdd, {
                Id_Usuario: idUsuario,
                Id_Inventario: idInventario,
                Cantidad: cantidad
            });
            if (response.status === 200) {
                setAddedProduct(product);
                setShowModal(true);
            }
        } catch (error) {
            console.error("Error al agregar al carrito", error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setAddedProduct(null);
    };

    const styleCard = {
        position: 'relative',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        paddingBottom: '190%',
        height: 0,
        margin: '0 auto',
        width: '100%',
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: '0 3px 8px rgba(0.2, 0.2, 0.2, 1)'
    };

    const cardContent = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    };

    const styleImg = {
        width: 'auto',
        height: '150px',
        objectFit: 'cover',
        filter: 'drop-shadow(1px 1px 10px green)'
    };

    return (
        <div>
            <h1 className='titulo-principal'>Tekne Solution</h1>
            <nav className="navbar">
                <div className="container-fluid">
                    <form className="d-flex ms-auto col-md-5 mb-3">
                        <input 
                            style={{boxShadow: '0 3px 8px rgba(0.2, 0.2, 0.2, 1)'}}
                            value={search} 
                            onChange={searcher} 
                            className="form-control me-2" 
                            type="search" 
                            placeholder="Buscar..." 
                            aria-label="Search" 
                        />
                    </form>
                </div>
            </nav>
            <div className='container fondo'>
                <div className='row'>
                    {producto.length > 0 ? (
                        producto
                            .filter(product => product.Stock > 0) 
                                .map((product) => (
                                <div key={product.Id_Producto} className='btn col-lg-3 col-md-4 col-sm-6 mb-3'>
                                    <div className='card1' style={styleCard}>
                                        <div className='card-body' style={cardContent}>
                                            <Link to={`/abrir/${product.Id_Inventario}`}>
                                                <h5 className='card-title btn btn-lg' ><TruncateText text={product.Nombre_Producto} maxLength={30} /></h5>
                                                <p className='card-img'>
                                                    <img style={styleImg} src={product.Img_uno} alt='Imagen del producto' />
                                                </p>
                                            </Link>
                                            <div>
                                                {/* <p className='card-text'><strong>Departamento:</strong> {product.Departamento}</p> */}
                                                <p className='card-text'><strong>Marca:</strong> <TruncateText text={product.Marca} maxLength={15} /></p>
                                                <p className='card-text'><strong>Especificaciones:</strong> <TruncateText text={product.Especificaciones} maxLength={30} /></p>
                                                <p className='card-text'><strong>Modelo:</strong> <TruncateText text={product.Modelo} maxLength={10} /></p>
                                                <p className='card-text'><strong><i className="fa-solid fa-box-open"></i></strong> {product.Stock}</p>
                                                <p className='card-text producto-precio'>
                                                    <strong>Precio:</strong> ${product.Precio_Unitario} 
                                                </p>
                                                <button 
                                                    className='btn btn-warning' 
                                                    onClick={() => addToCart(id, product.Id_Inventario, 1, product)}>
                                                    <i className="fa-solid fa-cart-shopping"></i> Agregar al carrito
                                                </button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div className='col-12'>
                            <div className="alert alert-warning text-center" role="alert">
                                Producto no encontrado
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {addedProduct && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Articulo agregado al carrito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Se agregó correctamente {addedProduct.Nombre_Producto}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseModal}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );    
}

export default CompMostrarUnInventario;
