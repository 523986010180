import React from 'react';
import { Navigate } from 'react-router-dom';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
function tokenExpirado(token) {
    if (!token) return true;

    const decodificarToken = parseJwt(token);
    const tiempoTranscurrido = Math.floor(Date.now() / 1000);

    return decodificarToken.exp < tiempoTranscurrido;
}
const ProtectedUserRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    // Verificar si el token está presente y si no ha expirado
    if (!token || tokenExpirado(token)) {
        return <Navigate to="/login" />;
    }

    // Decodificar el token para obtener el rol(parsear)
    const { role } = parseJwt(token);

    // Verificar si el rol es válido
    if (role !== 2) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedUserRoute;
